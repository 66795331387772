import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import './filters/index.js';

import DashboardPlugin from './plugins/dashboard-plugin';

Vue.config.productionTip = false;
Vue.use(DashboardPlugin, store);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
