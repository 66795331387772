export const namespaced = true;

export const getDefaultState = () => {
    return {
        name: '',
        is_anonymous: false,
        isInputFieldConfigurable: false,
        inputFieldConfiguration: null,
    };
};

export const state = getDefaultState();

export const mutations = {
    SET_NAME(state, name) {
        state.name = name;
    },
    SET_IS_ANONYMOUS(state, bool) {
        state.is_anonymous = bool;
    },
    SET_IS_INPUT_FIELD_CONFIGURABLE(state, bool) {
        state.isInputFieldConfigurable = bool;
    },
    SET_INPUT_FIELD_CONFIGURATION(state, configuration) {
        state.inputFieldConfiguration = configuration;
    },
};

export const actions = {
    setCampaign({ commit }, campaign) {
        commit('SET_NAME', campaign.name);
        commit('SET_IS_ANONYMOUS', parseInt(campaign.is_anonymous) === 1);
        const data = JSON.parse(campaign.data);
        commit(
            'SET_IS_INPUT_FIELD_CONFIGURABLE',
            data.isInputFieldConfigurable
        );
        commit('SET_INPUT_FIELD_CONFIGURATION', data.inputFieldConfiguration);
    },
};
