<template>
    <div id="app" :class="[page, classes]">
        <component :is="layout">
            <router-view :key="$route.fullPath" />
        </component>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus';
const default_layout = 'Page';

export default {
    name: 'Amplify',
    computed: {
        layout() {
            return this.$route?.meta?.layout || default_layout;
        },
        page() {
            return this.$route?.name || '';
        },
    },
    data() {
        return {
            classes: '',
        };
    },
    mounted() {
        EventBus.$on('APP_CLASS_UPDATE', (payload) => {
            this.updateClasses(payload);
        });
    },
    methods: {
        updateClasses(className) {
            this.classes = className;
        },
    },
};
</script>

<style lang="scss">
body {
    background-color: $default;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;

    &.page-not-found {
        background: none;
    }
}

.container {
    max-width: 500px !important;
    margin: 0 auto;
    padding: 0 !important;
}

.amplify-content {
    padding: 2rem 0;
    background-color: $default;
    min-height: 100vh;
    color: $black;
    display: flex;
    align-items: center;
}

.mobile-app {
    .container {
        max-width: 100% !important;
    }

    .amplify-content {
        padding: 1rem;
        align-items: start;
    }
}
</style>
