import Vue from 'vue';
import Vuex from 'vuex';
import * as feedback from '@/store/modules/feedback.js';
import * as campaign from '@/store/modules/campaign.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        feedback,
        campaign,
    },
    state: {
        progress: 0,
        isLoading: false,
    },
    mutations: {
        SET_PROGRESS(state, total) {
            state.progress = total;
        },
        SET_LOADING(state, bool) {
            state.isLoading = bool;
        },
    },
    actions: {},
});
