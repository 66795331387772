import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

const Amplify = () => import('./views/Amplify/Index.vue');
const Unsubscribe = () => import('./views/Unsubscribe/Index.vue');
const Home = () => import('./views/Home/Index.vue');
const NotFound = () => import('./views/Error/Index.vue');
const Demo = () => import('./views/Demo/Index.vue');

Vue.use(VueRouter);

const router = new VueRouter({
    store,
    mode: 'history',
    base: import.meta.env.VITE_APP_BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: [
        {
            path: '*',
            redirect: {
                name: '404',
                params: {
                    resource: 'page',
                },
            },
        },
        {
            path: '/404',
            name: '404',
            component: NotFound,
            props: true,
            meta: {
                title: "Sorry! We can't find your page.",
                layout: 'Page',
                scopes: [],
            },
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            props: true,
            meta: {
                title: '',
                layout: 'Page',
                scopes: [],
            },
        },
        {
            path: '/a/:amplify_id',
            name: 'amplify',
            component: Amplify,
            props: true,
            meta: {
                title: '',
                layout: 'Page',
                scopes: [],
            },
            children: [
                {
                    path: '/a/:amplify_id/:contact_code',
                    name: 'amplify-contacts',
                    component: Amplify,
                    props: true,
                    meta: {
                        title: '',
                        layout: 'Page',
                        scopes: [],
                    },
                },
                {
                    path: '/a/:amplify_id/:contact_code/:rating',
                    name: 'amplify-contacts-rating',
                    component: Amplify,
                    props: true,
                    meta: {
                        title: '',
                        layout: 'Page',
                        scopes: [],
                    },
                },
            ],
        },
        {
            path: '/u/:amplify_id/:contact_code',
            name: 'amplify-unsubscribe',
            component: Unsubscribe,
            props: true,
            meta: {
                title: '',
                layout: 'Page',
                scopes: [],
            },
        },
        {
            path: '/d/:amplify_id',
            name: 'amplify-demo',
            component: Demo,
            props: true,
            meta: {
                title: '',
                layout: 'Page',
                scopes: [],
            },
        },
    ],
});

// router.beforeEach((to, from, next) => {
//     document.title = to.meta.title + " | Amplify";

//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//         next();
//     } else if (to.matched.some((record) => record.meta.guest)) {
//         // Guest pages
//         next();
//     } else {
//         next();
//     }
// });

export default router;
