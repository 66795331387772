export const namespaced = true;

export const getDefaultState = () => {
    return {
        rating: null,
        hasSubmitted: false,
        locations: [],
    };
};

export const state = getDefaultState();

export const mutations = {
    SET_RATING(state, rating) {
        state.rating = rating;
    },
    SET_SUBMITTED(state, bool) {
        state.hasSubmitted = bool;
    },
    SET_LOCATIONS(state, arr) {
        state.locations = arr;
    },
};

export const actions = {
    setFeedback({ commit }, rating) {
        commit('SET_RATING', rating);
    },
};
